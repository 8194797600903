<template>
  <div>
    <!-- SM screen -->
    <!-- <h5 class="d-block d-sm-none" style="max-width: 450px; min-width: 140px; word-wrap: break-word;"><strong>Video Creatives</strong></h5> -->
    <!-- * EXCEPT SM screen -->
    <!-- <h5 class="d-none d-sm-block" ><strong>Video Creatives</strong></h5> -->

    <CreativesTable :fileList="fileList" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
    }
  },
  props: ['receivedCreatives'],

  components: {
    CreativesTable: () => import('@/components/custom/videoCreativesTable/index.vue'),
  },
}
</script>

<style>

</style>
